import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormLabel,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { MENU_NAMES } from "../../core/utils/constants";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import TextInput from "../../theme/components/input/text-input";
import { colors } from "../../theme/styles/colors";
import { useLocation, useNavigate } from "react-router-dom";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import SwitchInput from "../../theme/components/input/switch-input";

const AddRole = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const [checkedItems, setCheckedItems] = React.useState([false, false]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [isLoading, setIsLoading] = useState(false);
  const [initialState, setInitialState] = useState({
    roleName: "",
  });
  const { STRING_VALIDATOR } = SCHEMA_VALIDATOR;
  const validationSchema = yup.object({
    roleName: STRING_VALIDATOR,
  });
  return (
    <Container maxW="container.xll" padding={"13px "}>
      <Box>
        <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.ADD_ROLE)} />
      </Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialState}
        onSubmit={() => {
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
            navigate(ROUTE_LINKS[MENU_NAMES.ENQUIRY], {
              state: {
                currentPage:params.state.currentPage,
                currentPageSize:params.state.currentPageSize
              },
            });
          }, 2000);
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form
            noValidate={true}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box>
                <Heading variant={"h1"} marginBottom={"13px"}>
                  Create New Role
                </Heading>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                marginBottom={"13px"}
              >
                <Button
                  variant={"warning"}
                  onClick={() => {
                    navigate("/general/role", {
                      state: {
                        currentPage:params.state.currentPage,
                        currentPageSize:params.state.currentPageSize
                      },
                    });
                  }}
                >
                  Discard
                </Button>
                <Button variant={"primary"} type="submit">
                  {" "}
                  {isLoading ? (
                    <CircularProgress
                      isIndeterminate
                      size="24px"
                      color={colors.white}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Box>
            <Box
              display={"flex"}
              gap={"13px"}
              borderRadius={"10px"}
              flexDirection={"column"}
            >
              <Box
                padding={"13px"}
                borderRadius={"10px"}
                border={"1px solid #e6e6e6"}
              >
                <SimpleGrid
                  columns={{ base: "1", lg: "1", md: "3", lg: "3" }}
                  spacingX="30px"
                  spacingY="0px"
                >
                  <TextInput
                    type="text"
                    isRequired={true}
                    name="roleName"
                    value={values.roleName}
                    label={{
                      text: "Role Name",
                      colors: errors.roleName ? "red !important" : "",
                      variant: errors.roleName ? "errored" : "",
                    }}
                    onChange={(e) => handleChange(e)}
                    placeholder={""}
                    variant={errors.roleName ? "errored" : "flushed"}
                    isReadOnly={isLoading}
                  />
                  <SwitchInput
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={false}
                    value={values.isActive}
                    name="isActive"
                    variant={errors.isActive ? "errored" : "flushed"}
                    label={{
                      text: "Active",
                      colors: errors.isActive ? "red !important" : "",
                      variant: errors.isActive ? "errored" : "",
                      fontSize: "12px",
                    }}
                    formControl={{ id: "isActive" }}
                    onChange={handleChange}
                  />
                </SimpleGrid>

                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem
                    borderRadius={"10px"}
                    border={"1px solid #e6e6e6"}
                  >
                    <AccordionButton >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        Menu Permissions
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Box>
                        <Stack
                          spacing={5}
                          direction="row"
                          padding={"0px 0px 13px 3px"}
                        >
                          <FormLabel
                            fontSize={"14px"}
                            fontWeight={"400"}
                            color={"black"}
                            marginLeft={"7px"}
                          >
                            Administrator
                          </FormLabel>
                          <Checkbox
                            __css={{
                              "& .chakra-checkbox__control": {
                                background: "#e2e8f0",
                              },
                            }}
                            iconSize="30px"
                            marginLeft={"57px"}
                            size={"lg"}
                            display={"flex"}
                            colorScheme="yellow"
                          >
                            <Text fontSize={13} >
                              Select All
                            </Text>
                          </Checkbox>
                        </Stack>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Enquiry
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0!important",
                                  },
                                }}
                                isDisabled={false}
                                display={"flex"}
                                colorScheme="yellow"
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Feedback Report
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Enquiry Status
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Board
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Qual/Stream
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Reference
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Reference Type
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Immigration
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Visa Country
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                size="lg"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                isDisabled={false}
                                colorScheme="yellow"
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Visa Type
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Visa College
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Visa City
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                isChecked={checkedItems[1]}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Visa Course
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              File Status
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              File Type
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              File Mode
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              File Document
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Branch
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Pending Work
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Pending Documents
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Account
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Fund Type
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Grade
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Bank
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              General
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              User
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                          padding={"0px 0px 13px 3px"}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Module
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              State
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          border={1}
                        >
                          <Box
                            w="38%"
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              Distrct
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                          <Box
                            justifyContent="space-between"
                            display="flex"
                            padding={2}
                            w="38%"
                          >
                            <FormLabel
                              fontSize={"14px"}
                              fontWeight={"400"}
                              color={"black"}
                            >
                              State
                            </FormLabel>
                            <Box
                              w={"60%"}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  View
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                colorScheme="yellow"
                                isDisabled={false}
                                size="lg"
                              >
                                <Text fontSize={13} >
                                  Create
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Edit
                                </Text>
                              </Checkbox>
                              <Checkbox
                                __css={{
                                  "& .chakra-checkbox__control": {
                                    background: "#e2e8f0",
                                  },
                                }}
                                display={"flex"}
                                size="lg"
                                colorScheme="yellow"
                                isDisabled={false}
                              >
                                <Text fontSize={13} >
                                  Delete
                                </Text>
                              </Checkbox>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AddRole;
