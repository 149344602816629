import { Box, Button, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { USER_AUTH_TOKEN_KEY, USER_IP_ADDRESS, MENU_NAMES } from "../core/utils/constants";
import { getIpAddress } from "../core/utils/common-function";
import TimerModal from "../theme/components/modals/timer-modal";
import { userSignOut, setOTPData } from "../core/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_LINKS } from "../core/utils/route-links";
const ProtectedRoutes = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((_) => _);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isIpChangeOpen, setIsChangeIpOpen] = useState(false);
  const goBack = () => {
    navigate("/");
  };
  useEffect(() => {
    let ipAddressInterval;
    const jsonToken = localStorage.getItem(USER_AUTH_TOKEN_KEY);
    const userIpAddress = localStorage.getItem(USER_IP_ADDRESS);
    if (jsonToken) {
      const token = JSON.parse(jsonToken);
      if ((token != null || token != undefined)) {
        setIsAuthorized(true);
        ipAddressInterval = setInterval(async () => {
          const ip = (await getIpAddress()).data?.ip;
          if (ip != userIpAddress) {
            dispatch(userSignOut());
            navigate(ROUTE_LINKS[MENU_NAMES.LANDING]);
            clearInterval(ipAddressInterval);
            setIsAuthorized(false);
          }
        }, 10000);
        localStorage.setItem("otpIntervalTime",JSON.stringify(ipAddressInterval));
      }
    } else
      setIsAuthorized(false);
  }, []);

  if (isAuthorized) {
    return props.children;
  }

  return (
    <Box>
      <TimerModal
        isOpen={isIpChangeOpen}
        onClose={() => {
          setIsChangeIpOpen(false);
        }}
      />
      <Box display={"flex"} justifyContent={"center"}>
        <Image src="/images/error-boundary.svg" height={"600px"} />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
      </Box>
    </Box>
  );
};

export default ProtectedRoutes;
