import axios from "axios";
import {
  ERROR_CODES,
  REFRESH_TOKEN_KEY,
  USER_AUTH_TOKEN_KEY,
} from "../utils/constants";
import handleError from "../utils/server-side-errors";
import { SuccessAlert, WarningAlert } from "../utils/common-sweet-alert";

let isRefreshTokenRetry = false;
let requestRetries = 0;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${JSON.parse(
      localStorage.getItem(USER_AUTH_TOKEN_KEY)
    )}`,
    "Content-type": "application/json",
  },
  withCredentials: false,
});

api.interceptors.request.use(
  (config) => {
    if (config.headers.Authorization === "Bearer null")
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem(USER_AUTH_TOKEN_KEY)
      )}`;
    if (config.url == 'https://jsonip.com/')
      document.getElementById("loader").style.display = "none";
    else
      document.getElementById("loader").style.display = "flex";
    requestRetries += 1;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (requestRetries != 0) requestRetries -= 1;
    if (requestRetries == 0) isRefreshTokenRetry = false;

    if (response.config.url.includes("reset-password")) {
      SuccessAlert(response.data.message);
      window.location.href = "/";
    }
    document.getElementById("loader").style.display = "none";
    return response;
  },
  async (error) => {
    if (error.config.url.includes("/Account/login")) {
      handleError(error);
      return;
    }

    handleError(error);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        if (!isRefreshTokenRetry) {
          isRefreshTokenRetry = true;
          const refreshToken = JSON.parse(
            localStorage.getItem(REFRESH_TOKEN_KEY)
          );
          const accessToken = JSON.parse(
            localStorage.getItem(USER_AUTH_TOKEN_KEY)
          );
          const response = await api.post("/Account/refresh-token", {
            refreshToken,
            accessToken,
          });

          localStorage.setItem(
            USER_AUTH_TOKEN_KEY,
            JSON.stringify(response.data.data.accessToken)
          );
          localStorage.setItem(
            REFRESH_TOKEN_KEY,
            JSON.stringify(response.data.data.refreshToken)
          );
          document.body.classList.remove("loader");
        }
        originalRequest.headers.Authorization = `Bearer ${JSON.parse(
          localStorage.getItem(USER_AUTH_TOKEN_KEY)
        )}`;
        return axios(originalRequest);
      } catch (error) {
        document.body.classList.remove("loader");
        // Handle refresh token error or redirect to login
        if (
          isRefreshTokenRetry &&
          (error.response.status == ERROR_CODES.BAD_REQUEST ||
            error.response.status == ERROR_CODES.INTERNAL_SERVER_ERROR)
        ) {
          WarningAlert("Session expired. Please sign-in again to continue!");
          requestRetries = 0;
          isRefreshTokenRetry = false;
          localStorage.clear();
          window.location.href = "/";
        }
      }
    }
    document.body.classList.remove("loader");
    return Promise.reject(error);
  }
);

export default api;
