import React, { useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  SimpleGrid,
  Collapse,
  Button,
  CircularProgress,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { colors } from "../../../theme/styles/colors";
import BreadcrumbBox from "../../../theme/components/breadcrumb";
import TextInput from "../../../theme/components/input/text-input";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Pagination from "../../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../../core/utils/navbar-items";
import {
  MENU_NAMES,
  MODULE_ENUMS,
  PAGE_SIZE,
  ROLES,
} from "../../../core/utils/constants";
import { GRID_COLUMNS } from "../../../core/utils/grid-columns";
import SelectInput from "../../../theme/components/input/select-input";
import { GoFilter } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import useTableHeightFix from "../../../core/hooks/useTableHeightFix";
import api from "../../../core/api";
import {
  getFilterCount,
  createFilterPayload,
  deleteById,
  getById,
  handleFilterChange,
  handleResetState,
  CREATE_RESPONSE,
  handleActiveFilter,
  createFilterState,
} from "../../../core/utils/common-function";
import SCHEMA_VALIDATOR from "../../../core/utils/schema-validator";
import DeleteModal from "../../../theme/components/modals/delete-modal";
import SwitchInput from "../../../theme/components/input/switch-input";
import { useSelector } from "react-redux";
import Grid from "../../../theme/components/grid/grid";
const FileType = (props) => {
  const {moduleId} = props;
  const selector = useSelector((_) => _);
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [tableRowData, setTableRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileTypeId, setFileTypeId] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR || 
selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.FILE_TYPE.filter((x) => x.Active && x.Visibility)
  );
  const [initialState, setInitialState] = useState({
    name: "",
    isActive: false,
  });
  const [filterState, setFilterState] = useState({
    name: "",
    isActive: null,
  });
  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };
  const [activeFilter, setActiveFilter] = useState(0);
  const handleToggle = () => {
    setShow(!show);
  };

  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
  });
  const { STRING_VALIDATOR } = SCHEMA_VALIDATOR;
  const validationSchema = yup.object({
    name: STRING_VALIDATOR,
  });
  const getAllRecords = () => {
    api
      .post("/FileType/getAll", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.FILE_TYPE, res.data.data.data));
      })
      .catch((err) => {});
  };
 
  const getRecord = async () => {
    try {
      if (fileTypeId) {
        let record = (await getById("FileType", fileTypeId)).data;
        setInitialState({
          isActive: record.isActive,
          name: record.name,
          ...record,
        });
        onOpen();
      }
    } catch (error) {}
  };
  useEffect(() => {
    document.title = "File Type | Digital Filing";
    getRecord();
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
    getAllRecords();
  }, [apiPayload]);

  useEffect(() => {
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [windowHeight]);

  useEffect(() => {
    getRecord();
  }, [isEdit, isView]);

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  const onModelClose = () => {
    setFileTypeId(null);
    setIsEdit(false);
    setIsView(false);
    onClose();
    setInitialState({
      isActive: false,
      name: "",
    });
  };
  const onDelete = async () => {
    await deleteById("FileType", fileTypeId);
    getAllRecords();
    setIsDeleteOpen(false);
    onModelClose();
  };
  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleActiveFilter('delete',moduleId)
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
    // getAllRecords();
  };

  const lastActiveFilter = async ()=>{
    const lastFilter = await handleActiveFilter('get', moduleId);
    if (lastFilter) {
      const filledFilter = JSON.parse(lastFilter.data.data);
      if (filledFilter) {
        setAPIPayload({
          ...apiPayload,
          filter: filledFilter,
          pageSize: PAGE_SIZE,
          pageIndex: 1,
        });
        createFilterState(filledFilter, setFilterState, setActiveFilter);
      }
    }
  };

  useEffect(()=>{
    lastActiveFilter()
  },[])

  return (
    <Box>
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={onDelete}
      />
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.FILE_TYPE)} />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>FileType</Heading>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"0px"}
                height={"30px"}
                width={"30px"}
                lineHeight={"30px"}
              >
                {activeFilter}
              </Box>
            </Button>
            {props.hasCreate && (
              <Button variant={"primary"} onClick={onOpen}>
                <IoMdAddCircleOutline fontSize={"20px"} />
                <Text marginLeft={"5px"} className="btnText">
                  Create New
                </Text>
              </Button>
            )}
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(filterState);
                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });
                const jsonString = JSON.stringify(payloadArr);
                handleActiveFilter('post','',{moduleId:moduleId, StateJson:jsonString  })
              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <TextInput
                  type="text"
                  placeholder={""}
                  name="Name"
                  value={filterState.Name?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Name" )
                  }
                  marginTop="20px"
                  formControl={{ id: "name" }}
                  label={{ text: "Name" }}
                />
                <SelectInput
                  formControl={{ id: "isActive", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.IsActive?.value}
                  name="IsActive"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState,"Status")
                  }
                  label={{
                    text: "Status",
                  }}
                  options={[
                    { text: "Active", value: true },
                    { text: "Inactive", value: false },
                  ]}
                />
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>
        <Grid
          name={"fileType"}
          columns={GRID_COLUMNS.FILE_TYPE}
          aColumns={columns}
          data={data}
          permission={props}
          hasDelete={true}
          hasExport={hasExport}
          setColumns={(e) => setColumns(e)}
          isPopupComponent={true}
          setRecordId={(fileTypeId, props) => {
            if (props.isView) {
              setIsView(true);
              setIsEdit(false);
            } else if (props.isEdit) {
              setIsView(false);
              setIsEdit(true);
            } else if (props.isDelete) setIsDeleteOpen(true);
            setFileTypeId(fileTypeId);
          }}
        />
        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) =>
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          handleRowStateChange={(e) => {
            setAPIPayload({
              ...apiPayload,
              pageIndex: 1,
              pageSize: e.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
          minWidth={"20%"}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialState}
              enableReinitialize={true}
              onSubmit={(values) => {
                let finalPayload = {
                  name: values.name,
                  isActive: values.isActive,
                };
                if (isEdit) {
                  finalPayload.fileTypeId = fileTypeId;
                  api
                    .put(`/FileType/replace`, { ...finalPayload })
                    .then((res) => {
                      onModelClose();
                      getAllRecords();
                    })
                    .catch((err) => {
                      console.log(err, "err");
                    });
                  return;
                }
                api
                  .post(`/FileType/create`, { ...finalPayload })
                  .then((res) => {
                    onModelClose();
                    getAllRecords();
                  })
                  .catch((err) => {
                    console.log(err, "err");
                  });
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form
                  noValidate={true}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <ModalHeader borderBottom={"1px solid #EDEDED"}>
                    {isEdit
                      ? "Update File Type"
                      : isView
                      ? "View File Type"
                      : "Create New File Type"}
                  </ModalHeader>
                  <ModalCloseButton
                    bg={colors.primaryColor}
                    color={colors.white}
                    position={"absolute"}
                    top={"15px"}
                    onClick={onModelClose}
                  />
                  <ModalBody padding={"20px"}>
                    <SimpleGrid
                      columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                      spacingX="-3px"
                      spacingY="-6px"
                      gap={"10px"}
                    >
                      <TextInput
                        formControl={{ id: " Name" }}
                        type="text"
                        name="name"
                        isRequired={true}
                        isReadOnly={isView}
                        placeholder={""}
                        value={values.name}
                        label={{
                          text: "Name",
                          colors: errors.name ? "red !important" : "",
                          variant: errors.name ? "errored" : "",
                        }}
                        onChange={(e) => handleChange(e)}
                        variant={errors.name ? "errored" : "flushed"}
                      />
                      <SwitchInput
                        placeholder={""}
                        isRequired={true}
                        isReadOnly={isView}
                        value={values.isActive}
                        name="isActive"
                        variant={errors.isActive ? "errored" : "flushed"}
                        label={{
                          text: "Active",
                          colors: errors.isActive ? "red !important" : "",
                          variant: errors.isActive ? "errored" : "",
                          fontSize: "12px",
                        }}
                        formControl={{ id: "isActive" }}
                        onChange={handleChange}
                      />
                    </SimpleGrid>
                  </ModalBody>
                  <ModalFooter
                    justifyContent={"space-between"}
                    borderTop={"1px solid #EDEDED "}
                  >
                    <Button variant={"secondaryOutline"} onClick={onModelClose}>
                      {isView ? "Back" : "Discard"}
                    </Button>
                    {!isView && (
                      <Button
                        variant={"primary"}
                        color={colors.white}
                        type="submit"
                      >
                        {isLoading ? (
                          <CircularProgress
                            isIndeterminate
                            size="24px"
                            color={colors.white}
                          />
                        ) : isEdit ? (
                          "Save"
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    )}
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      </Container>
    </Box>
  );
};
export default FileType;
