
import React, { useEffect, useState } from "react";
import Pagination from "../../theme/components/pagination/pagination";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { MENU_NAMES,  ROLES,PAGE_SIZE } from "../../core/utils/constants";
import { useSelector } from "react-redux";
import { colors } from "../../theme/styles/colors";
import api from "../../core/api";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import {
  CREATE_RESPONSE,
  getCaseStatusColour,
} from "../../core/utils/common-function";
import Grid from "../../theme/components/grid/grid";

function Enquiries(props) {
  const selector = useSelector((_) => _);
  const params = useLocation();
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(params.state?.currentPage ? params.state.currentPage : 1);
  const [currentPageSize, setCurrentPageSize] = useState(params.state?.currentPageSize ? params.state.currentPageSize : PAGE_SIZE);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR || 
selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.DAILY_ROUTINE_ENQUIRIES.filter((x) => x.Active && x.Visibility)
  );
  props.apiPayload.pageSize = currentPageSize;
  props.apiPayload.pageIndex =currentPage;
  const onChange = (page) => {
    props.setAPIPayload({
      ...props.apiPayload,
      pageIndex: page,
    });
  };

  const getAllRecords = () => {
    api
      .post("/Dashboard/getDailyEnquiries", props.apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(
          CREATE_RESPONSE(
            GRID_COLUMNS.DAILY_ROUTINE_ENQUIRIES,
            res.data.data.data
          )
        );
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    if (props.tabIndex == 0 && props.apiPayload.branchId != "") getAllRecords();
  }, [props.apiPayload, selector.branch.userBranchId, props.tabIndex]);

  return (
    <div>
      <Grid
        name={"enquiries"}
        columns={GRID_COLUMNS.DAILY_ROUTINE_ENQUIRIES}
        aColumns={columns}
        data={data}
        navigateUrl={props.isForDashboard ? ROUTE_LINKS[MENU_NAMES.DASHBOARD] : ROUTE_LINKS[MENU_NAMES.DAILY_ROUTINE]}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        tabIndex={props.tabIndex}
        permission={props.permission}
        hasExport={hasExport}
        setColumns={(e) => setColumns(e)}
        viewRoute={ROUTE_LINKS[MENU_NAMES.EDIT_VIEW_ENQUIRY]}
        editRoute={ROUTE_LINKS[MENU_NAMES.EDIT_VIEW_ENQUIRY]}
      />
      <Pagination
        className="pagination-bar"
        currentPage={props.apiPayload.pageIndex}
        totalCount={totalCount}
        pageSize={props.apiPayload.pageSize}
        onPageChange={(page) =>
        {
          setCurrentPage(page);
          props.setAPIPayload({
            ...props.apiPayload,
            pageIndex: page,
          })
        }
        }
        handleRowStateChange={(e) => {
          setCurrentPageSize(e.target.value);
          props.setAPIPayload({
            ...props.apiPayload,
            pageIndex: 1,
            pageSize: e.target.value,
          });
        }}
        activeBackgroundColor={colors.primaryColor}
        activeTextColor={colors.gray.gray100}
        onChange={onChange}
      />
    </div>
  );
}

export default Enquiries;
