import React, { useEffect } from "react";
import {
  Badge,
  Box,
  Container,
  Heading,
  Tbody,
  TableContainer,
  Td,
  Th,
  Tr,
  ModalFooter,
  Table,
  Thead,
  SimpleGrid,
  Select,
  Icon,
  Collapse,
  Fade,
  Text,
} from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import Pagination from "../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { MENU_NAMES } from "../../core/utils/constants";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { GoFilter } from "react-icons/go";
import { BiExport } from "react-icons/bi";
import useTableHeightFix from "../../core/hooks/useTableHeightFix";
import { useSelector } from "react-redux";

const Account = (props) => {
  const selector = useSelector((_) => _);
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [current, setCurrent] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const onChange = (page) => {
    setCurrent(page);
  };
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "Account | Digital Filing";
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [windowHeight]);

  return (
    <Box>
      <Container maxW="container.xll" padding={"20px"}>
        <Box>
          <BreadcrumbBox
            route={GET_BREADCRUMB_ROUTES(MENU_NAMES.ACCOUNT, true)}
          />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>Account</Heading>
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"5px"}
              >
                24
              </Box>
            </Button>
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <SimpleGrid
              columns={{ base: "1", sm: "1", md: "5", lg: "5" }}
              spacingX="40px"
              padding={"20px"}
              paddingBottom={"0px"}
            ></SimpleGrid>
            <Box
              display={"flex"}
              justifyContent={"end"}
              paddingRight={"20px"}
              paddingBottom={"20px"}
            >
              <Button
                fontSize={{ base: "12px", sm: "13px", md: "14px", lg: "14px" }}
                variant={"primary"}
              >
                Search
              </Button>
              <Button
                fontSize={{ base: "12px", sm: "13px", md: "14px", lg: "14px" }}
                variant={"warning"}
                marginLeft={"10px"}
              >
                Reset
              </Button>
            </Box>
          </Collapse>
        </Box>

        <Box
          border={"1px solid #e6e6e6"}
          borderRadius={"10px"}
          padding={"10px"}
        >
          <Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
            <Box display={"flex"} justifyContent={"end"} gap={"10px"}>
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                spacingX="40px"
                spacingY="20px"
                minWidth={"150px"}
              >
                <Select variant={"flushed"} marginBottom={5} display={"flex"}>
                  <option value="">
                    <Checkbox></Checkbox> All
                  </option>
                  {GRID_COLUMNS.ACCOUNT.map((column, index) => (
                    <option value={column.Name}>
                      <Checkbox></Checkbox> {column.Name}
                    </option>
                  ))}
                </Select>
              </SimpleGrid>
              {[1, 3].find((x) => x == selector.auth.login.RoleId) && (
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  marginLeft={"10px"}
                >
                  <BiExport fontSize={"20px"} />
                  <Text marginLeft={"5px"}>Export</Text>
                </Button>
              )}
            </Box>
          </Box>
          <TableContainer fontSize={"15px"} borderRadius={"10px"}>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th paddingLeft={"20px"} paddingRight={"5px"}>
                    Action
                  </Th>
                  {GRID_COLUMNS.ACCOUNT.map((column, index) => (
                    <Th>{column.Name}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td
                    rowSpan={"10"}
                    colSpan={"28"}
                    textAlign={"center"}
                    padding={"150px"}
                  >
                    NO RECORDS FOUND
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={100}
          pageSize={10}
          onPageChange={(page) => setCurrentPage(page)}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
          total={50}
        />
      </Container>
    </Box>
  );
};
export default Account;
