import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools"; // import utility to set light and dark mode props
import { colors } from "./colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);
// define a custom variant
const colorfulVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    tab: {
      borderBottom: "5px solid green",
      borderColor: "transparent",
      // use colorScheme to change background color with dark and light mode options
  
      borderTopRadius: "lg",
      color: colors.black,
      _selected: {
        borderBottom: " 5px solid  #F0AC00",
        color: colors.primaryColor,
      },
    },
    tablist: {
      borderBottom: "2x solid black",
    },
    tabpanel: {
      borderBottomRadius: "lg",
      borderTopRightRadius: "lg",
    },
  };
});

const variants = {
  colorful: colorfulVariant,
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants });
