import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const TimerModal = ({ isOpen, onClose, onDelete, title }) => {
  return (
    <Modal isOpen={isOpen}
      onClose={onClose}
      variant="wide"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom={"1px solid #EDEDED"}>Important</ModalHeader>
        <ModalCloseButton
          position={"absolute"}
          top={"15px"}
        />
        <ModalBody  padding={"20px"}>
          <span
            size="sm"
            textAlign={"center"}
            fontSize={"20px"}
            fontWeight={"700"}
          >
            {title ? title : "Please make sure you are connected to the network of your Branch"}
          </span>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TimerModal;
