import { OTP_REDUCER_DATA } from "../../utils/constants";

const initialState = { login: {} };
const OTPReducer = (state = initialState, actions) => {
    const { type, payload } = actions;
    switch (type) {

        case OTP_REDUCER_DATA: {
            return {
                ...state,
                otpData: payload
            }
        }

        default: {
            return state;
        }
    }
};

export default OTPReducer;
