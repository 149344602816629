import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Collapse,
  Button,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { colors } from "../../../theme/styles/colors";
import BreadcrumbBox from "../../../theme/components/breadcrumb";
import TextInput from "../../../theme/components/input/text-input";
import Pagination from "../../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../../core/utils/navbar-items";
import { MENU_NAMES, MODULE_ENUMS, PAGE_SIZE, ROLES } from "../../../core/utils/constants";
import { GRID_COLUMNS } from "../../../core/utils/grid-columns";
import { GoFilter } from "react-icons/go";
import useTableHeightFix from "../../../core/hooks/useTableHeightFix";
import {
  getFilterCount,
  createFilterPayload,
  getAll,
  handleFilterChange,
  handleResetState,
  CREATE_RESPONSE,
  convertDateFormat,
  handleActiveFilter,
  createFilterState,
} from "../../../core/utils/common-function";
import api from "../../../core/api";
import { useSelector } from "react-redux";
import Grid from "../../../theme/components/grid/grid";
import { ROUTE_LINKS } from "../../../core/utils/route-links";
import { useLocation, useNavigate } from "react-router-dom";

const PendingWork = (props) => {
  const { moduleId } = props
  const selector = useSelector((_) => _);
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFilterState({ ...filterState, [name]: value });
  // };
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.PENDING_WORK.filter((x) => x.Active && x.Visibility)
  );
  const [branch, setBranch] = useState([]);
  const [isToDate, setIsToDate] = useState(true);
  const [show, setShow] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const params = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(params.state ? params.state.currentPage : 1);
  const [currentPageSize, setCurrentPageSize] = useState(params.state ? params.state.currentPageSize : PAGE_SIZE);
  const [minToDate, setMinToDate] = useState(
    convertDateFormat(new Date(), "yyyy-mm-dd")
  );
  const [filterState, setFilterState] = useState({
    Name: "",
    FromDate: "",
    ToDate: "",
  });
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: currentPageSize,
    pageIndex: currentPage,
    branchId: selector.branch.userBranchId,
  });
  const [activeFilter, setActiveFilter] = useState(0);
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });

  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };

  const handleToggle = () => {
    setShow(!show);
  };

  const getAllPromises = () => {
    const Branch = getAll("Branch", payload);

    Promise.all([Branch])
      .then((res) => {
        const [branch1] = res;
        const Branch = branch1.data.data.map((branch1) => ({
          text: branch1.name,
          value: branch1.branchId,
        }));
        setBranch(Branch);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleActiveFilter('delete', moduleId)
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
    // getAllRecords();
  };
  const lastActiveFilter = async () => {
    const lastFilter = await handleActiveFilter('get', moduleId);
    if (lastFilter) {
      const filledFilter = JSON.parse(lastFilter.data.data);
      if (filledFilter) {
        setAPIPayload({
          ...apiPayload,
          filter: filledFilter,
          pageSize: PAGE_SIZE,
          pageIndex: 1,
        });
        createFilterState(filledFilter, setFilterState, setActiveFilter);
      }
    }
  };

  useEffect(() => {
    lastActiveFilter()
  }, [])

  const getAllRecords = () => {
    api
      .post("/ImmigrationFile/getPendingWork", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.PENDING_WORK, res.data.data.data));
      })
      .catch((err) => { });
  };
  useEffect(() => {
    document.title = "pending Work | Digital Filing";
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [windowHeight]);

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    document.title = "Pending Work";
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getAllRecords();
      getAllPromises();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId]);

  return (
    <Box width={"100%"}>
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox
            route={GET_BREADCRUMB_ROUTES(MENU_NAMES.PENDING_WORK)}
          />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>Pending Work</Heading>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"0px"}
                height={"30px"}
                width={"30px"}
                lineHeight={"30px"}
              >
                {activeFilter}
              </Box>
            </Button>
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
        >
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(filterState);
                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });
                const jsonString = JSON.stringify(payloadArr);
                handleActiveFilter('post', '', { moduleId: moduleId, StateJson: jsonString })
              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "3", lg: "3" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <TextInput
                  type="text"
                  name="Name"
                  value={filterState?.Name?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Applicant Name")
                  }
                  formControl={{ id: "Applicant Name" }}
                  placeholder={""}
                  variant="flushed"
                  label={{ text: "Applicant Name" }}
                />
                <TextInput
                  type="date"
                  name="fromDate"
                  value={filterState.fromDate?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "From")
                  }
                  placeholder={""}
                  variant="flushed"
                  label={{ text: "From" }}
                />
                <TextInput
                  type="date"
                  name="toDate"
                  value={filterState.toDate?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "To")
                  }
                  placeholder={""}
                  variant="flushed"
                  label={{ text: "To" }}
                />
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>
        <Grid
          name={"pendingWork"}
          columns={GRID_COLUMNS.PENDING_WORK}
          aColumns={columns}
          data={data}
          currentPage={currentPage}
          navigateUrl={ROUTE_LINKS[MENU_NAMES.PENDING_WORK]}
          currentPageSize={currentPageSize}
          permission={props}
          hasExport={hasExport}
          hasEdit={false}
          setColumns={(e) => setColumns(e)}
          viewRoute={ROUTE_LINKS[MENU_NAMES.VIEW_IMMIGRATION]}
        />

        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) => {
            setCurrentPage(page);
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          }
          handleRowStateChange={(e) => {
            setCurrentPageSize(e.target.value);
            setAPIPayload({
              ...apiPayload,
              pageIndex: 1,
              pageSize: e.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
    </Box>
  );
};
export default PendingWork;
