import React, { useEffect } from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import { MENU_NAMES } from "../../core/utils/constants";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_RESPONSE,
  convertDateFormat,
  getById,
} from "../../core/utils/common-function";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";

const ViewUser = () => {
  const params = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const goBack = () => {
    navigate(params.state.navigateUrl, {
      state: {
        currentPage:params.state.currentPage,
        currentPageSize:params.state.currentPageSize,
        tabIndex:params.state.tabIndex
      },
    });
  };

  const getRecord = async () => {
    const user = (await getById("User", params.state.id)).data;
    setData(user);
  };

  useEffect(() => {
    document.title = "View User | Digital Filing";
    getRecord();
  }, []);

  return (
    <Container maxW="container.xll" padding={"20px "}>
      <Box>
        <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.VIEW_USER)} />
      </Box>
      <Box display={"flex"} justifyContent="space-between">
        <Box>
          <Heading variant={"h1"} marginBottom={"20px"}>
            View User
          </Heading>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={"10px"}
          marginBottom={"20px"}
        >
          <Button variant={"warning"} onClick={goBack}>
            Back
          </Button>
        </Box>
      </Box>
      <Box
        spacingX="30px"
        spacingY="0px"
        display={"flex"}
        w={"100%"}
        padding={"20px"}
        borderRadius={"10px"}
        border={"1px solid #e6e6e6"}
      >
        <Box maxW={"210px"} width={"100%"}>
          <Box position={"relative"} className="uploadImagesBox relative">
            <Avatar
              className="uploadedImageView"
              src={
                data.imagePath != null
                  ? `https://api.digitalfiling.co.in/Uploads/${data.imagePath}`
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png"
              }
            ></Avatar>
          </Box>
        </Box>
        <SimpleGrid
          columns={{ base: "1", sm: "1", md: "2", lg: "4" }}
          spacingX="30px"
          spacingY="0px"
          w={"100%"}
        >
          <TextInput
            type="text"
            name="name"
            value={data.name}
            label={{
              text: "Name",
            }}
            variant={"flushed"}
            isReadOnly={true}
          />
          <TextInput
            type="text"
            name="emailId"
            value={data.emailId}
            label={{
              text: "Email",
            }}
            variant={"flushed"}
            isReadOnly={true}
          />
          <TextInput
            type="text"
            name="dob"
            value={convertDateFormat(data.dob, "dd-mm-yyyy")}
            label={{
              text: "DOB",
            }}
            variant={"flushed"}
            isReadOnly={true}
          />
          <TextInput
            type="text"
            name="phoneNumber"
            value={data.phoneNumber}
            label={{
              text: "Phone Number",
            }}
            variant={"flushed"}
            isReadOnly={true}
          />
          <TextInput
            type="text"
            name="stateName"
            value={data.stateName}
            label={{
              text: "State",
            }}
            variant={"flushed"}
            isReadOnly={true}
          />
          <TextInput
            type="text"
            name="districtName"
            value={data.districtName}
            label={{
              text: "District",
            }}
            variant={"flushed"}
            isReadOnly={true}
          />
          <TextInput
            type="text"
            name="address"
            value={data.address}
            label={{
              text: "Address",
            }}
            variant={"flushed"}
            isReadOnly={true}
          />
        </SimpleGrid>
      </Box>
    </Container>
  );
};

export default ViewUser;
