import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkPermission, PARSE_JWT } from "../utils/common-function";
import { useEffect } from "react";
import { setRoutePermissions } from "../store/actions";
import { MENU_NAMES, USER_AUTH_TOKEN_KEY } from "../utils/constants";
import { useLocation } from "react-router-dom";
import { ROUTE_LINKS } from "../utils/route-links";

const layoutHOC = (Component) => {
  const CommomComp = (props) => {
    const location = useLocation();
    const selector = useSelector((_) => _);
    const dispatch = useDispatch();
    const [permission, setPermission] = useState({
      hasCreate: false,
      hasView: false,
      hasEdit: false,
      hasDelete: false,
      moduleId:""
    });
    const [branchId, setBranchId] = useState(selector.branch.userBranchId);

    const checkPermssionsFun = (permissions) => {
      const result = checkPermission(
        permissions ? permissions : selector.auth.login.Permission,
        null,
        location.pathname
      );
      if (result) {
        setPermission({
          hasCreate: result.hasCreate,
          hasView: result.hasView,
          hasEdit: result.hasEdit,
          hasDelete: result.hasDelete,
           moduleId:result.moduleId
        });
        dispatch(
          setRoutePermissions({
            hasCreate: result.hasCreate,
            hasView: result.hasView,
            hasEdit: result.hasEdit,
            hasDelete: result.hasDelete,
            moduleId:result.moduleId
          })
        );
      }
    };

    useEffect(() => {
      setBranchId(selector.branch.userBranchId);
      if (
        selector.auth.login.Permission &&
        selector.auth.login.Permission.length > 0
      ) {
        checkPermssionsFun();
        return;
      }

      /* TO NOT CHECK FOR PERMISSION ON LANDING PAGE AND VICE-VERSA */
      if (
        location.pathname !== ROUTE_LINKS[MENU_NAMES.LANDING] &&
        location.pathname !== ROUTE_LINKS[MENU_NAMES.FORGOT_PASSWORD] &&
        location.pathname !== ROUTE_LINKS[MENU_NAMES.RESET_PASSWORD]
      ) {
        const token = JSON.parse(localStorage.getItem(USER_AUTH_TOKEN_KEY));
        if (token) {
        const parsedToken = PARSE_JWT(token);
        checkPermssionsFun(parsedToken.Permission);
        }
      }
    }, [location, selector.branch.userBranchId]);
    return <Component permission={permission} branchId={branchId} {...props} />;
  };
  return CommomComp;
};

export default layoutHOC;
