import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Collapse,
  Checkbox,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { colors } from '../../theme/styles/colors';
import BreadcrumbBox from '../../theme/components/breadcrumb';
import TextInput from '../../theme/components/input/text-input';
import Pagination from '../../theme/components/pagination/pagination';
import { GET_BREADCRUMB_ROUTES } from '../../core/utils/navbar-items';
import { FILTER_ENUMS, MENU_NAMES, MODULE_ENUMS, PAGE_SIZE, ROLES } from '../../core/utils/constants';
import { GRID_COLUMNS } from '../../core/utils/grid-columns';
import { ROUTE_LINKS } from '../../core/utils/route-links';
import { GoFilter } from 'react-icons/go';
import SelectInput from '../../theme/components/input/select-input';
import { IoMdAddCircleOutline } from 'react-icons/io';
import useTableHeightFix from '../../core/hooks/useTableHeightFix';
import {
  getFilterCount,
  convertDateFormat,
  createFilterPayload,
  deleteById,
  getAll,
  getById,
  handleFilterChange,
  handleResetState,
  handleCheckboxFilterChange,
  CREATE_RESPONSE,
  followUpColors,
  getUsersByRole,
  createFilterState,
  handleActiveFilter,
} from '../../core/utils/common-function';
import api from '../../core/api';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import TextArea from '../../theme/components/input/text-area';
import { SuccessAlert } from '../../core/utils/common-sweet-alert';
import SCHEMA_VALIDATOR from '../../core/utils/schema-validator';
import DeleteModal from '../../theme/components/modals/delete-modal';
import {useSelector } from 'react-redux';
import Grid from '../../theme/components/grid/grid';
import { GRID_OTHER_ACTIONS } from '../../core/utils/grid-other-action';

const Enquiry = (props) => {
  const params = useLocation();
  const { moduleId } = props;
  const selector = useSelector((_) => _);
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEnquiryOpen, setEnquiryOpen] = useState(false);
  const [isToDate, setIsToDate] = useState(true);
  const [minToDate, setMinToDate] = useState(convertDateFormat(new Date(), 'yyyy-mm-dd'));
  const [currentPage, setCurrentPage] = useState(params.state ? params.state.currentPage : 1);
  const [currentPageSize, setCurrentPageSize] = useState(params.state ? params.state.currentPageSize : PAGE_SIZE);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [enquiryFileId, setEnquiryFileId] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const [isRemarkOpen, setRemarkOpen] = useState(false);
  const [isMarkFollowupOpen, setMarkFollowupOpen] = useState(false);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD ||
    selector.auth.login.RoleId == ROLES.FILLING_HEAD;
  const [columns, setColumns] = useState(GRID_COLUMNS.ENQUIRY.filter((x) => x.Active && x.Visibility));
  const [initialState, setInitialState] = useState({
    name: '',
    isActive: null,
  });
  const [remarkState, setRemarkState] = useState({
    enquiryFileId: null,
    remark: '',
  });
  const [markFollowup, setmarkFollowup] = useState({
    enquiryId: null,
    remark: '',
    followUpDate: '',
  });
  const [filterState, setFilterState] = useState({
    name: '',
    followUpDate: null,
    phoneNumber: '',
    fromDate: '',
    toDate: '',
    enquiryNumber: '',
    address: '',
    enquiryStatusId: '',
    counsellorId: '',
    pendingfollowup: {
      value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
      propertyName: 'FollowUpDate',
      operator: FILTER_ENUMS.LessThan,
      type: 'datetime',
      isGrouped: true,
      checked: true,
    },
    todayfollowup: {
      value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
      propertyName: 'FollowUpDate',
      operator: FILTER_ENUMS.Equals,
      type: 'datetime',
      isGrouped: true,
      checked: true,
    },
    futurefollowup: {
      value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
      propertyName: 'FollowUpDate',
      operator: FILTER_ENUMS.GreaterThan,
      type: 'datetime',
      isGrouped: true,
      checked: true,
    },
  });
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const [enquiryStatus, setEnquiryStatus] = useState([]);
  const [counsellors, setCounsellors] = useState([]);
  const [enquiryId, setEnquiryId] = useState(null);
  const [tableRowData, setTableRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activeFilter, setActiveFilter] = useState(0);
  const [apiPayload, setAPIPayload] = useState({
    filter: [
      {
        checked: true,
        value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
        propertyName: 'FollowUpDate',
        operator: FILTER_ENUMS.LessThan,
        type: 'datetime',
        isGrouped: true,
      },
      {
        checked: true,
        value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
        propertyName: 'FollowUpDate',
        operator: FILTER_ENUMS.Equals,
        type: 'datetime',
        isGrouped: true,
      },
      {
        checked: true,
        value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
        propertyName: 'FollowUpDate',
        operator: FILTER_ENUMS.GreaterThan,
        type: 'datetime',
        isGrouped: true,
      },
    ],

    pageSize: currentPageSize,
    pageIndex: currentPage,
    branchId: selector.branch.userBranchId,
  });
  const [enquiryState, setEnquiryState] = useState({
    enquiryStatusId: '',
  });

  const handleToggle = () => {
    setShow(!show);
  };
  const validationSchema = yup.object({
    remark: SCHEMA_VALIDATOR.SPECIAL_CHARECTERS_VALIDATOR,
  });
  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };
  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleActiveFilter('delete', moduleId);
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
  };

  const getAllRecords = () => {
    api
      .post('/Enquiry/getAll', apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.ENQUIRY, res.data.data.data));
      })
      .catch((err) => { });
  };

  const lastActiveFilter = async () => {
    const lastFilter = await handleActiveFilter('get', moduleId);
    if (lastFilter) {
      const filledFilter = JSON.parse(lastFilter.data.data);
      if (filledFilter) {
        setAPIPayload({
          ...apiPayload,
          filter: filledFilter,
          pageSize: PAGE_SIZE,
          pageIndex: 1,
        });
        createFilterState(filledFilter, setFilterState, setActiveFilter);
      }
    }
  };

  const getRecord = async () => {
    try {
      if (enquiryId) {
        let record = (await getById('Enquiry', enquiryId)).data;
        setInitialState({
          isActive: record.isActive,
          name: record.name,
        });
      }
    } catch (error) { }
  };

  useEffect(() => {
    getRecord();
  }, [isEdit, isView]);

  useEffect(() => {
    if (selector.branch.userBranchId) getAllPromises();
    lastActiveFilter();
    const filterSum = getFilterCount(filterState);
    setActiveFilter(filterSum);
  }, [selector.branch.userBranchId]);

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    document.title = 'Enquiry | Digital Filing';
    if (apiPayload.branchId != '' && apiPayload.branchId == selector.branch.userBranchId) {
      getAllRecords();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId]);

  useEffect(() => {
    resizeElementHeight(document.getElementsByClassName('chakra-table__container')[0]);
  }, [windowHeight]);

  const onModelClose = () => {
    setEnquiryId(null);
    setIsEdit(false);
    setIsView(false);
    onClose();
    setInitialState({
      isActive: false,
      name: '',
    });
  };

  const onDelete = async () => {
    await deleteById('Enquiry', enquiryId);
    getAllRecords();
    setIsDeleteOpen(false);
    onModelClose();
  };
  const getAllPromises = () => {
    const EnquiryStatus = getAll('EnquiryStatus', payload);
    const Counsellors = getUsersByRole(ROLES.COUNSELLOR, selector.branch.userBranchId);
    Promise.all([EnquiryStatus, Counsellors])
      .then((res) => {
        const [enquiryStatuses, branchCounsellors] = res;
        const EnquiryStatus = enquiryStatuses.data.data.map((enquiryStatus1) => ({
          text: enquiryStatus1.name,
          value: enquiryStatus1.enquiryStatusId,
        }));
        const counsellorResp = branchCounsellors.data.map((coun) => ({
          text: coun.name,
          value: coun.userId,
        }));
        setEnquiryStatus(EnquiryStatus);
        setCounsellors(counsellorResp);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };

  const onEnquiryClose = () => {
    setEnquiryOpen(false);
  };
  const onRmarkClose = () => {
    setRemarkOpen(false);
  };
  const onMarkFollowupClose = () => {
    setMarkFollowupOpen(false);
  };
  const enquiryStateValidationSchema = yup.object({
    enquiryStatusId: SCHEMA_VALIDATOR.GUID_VALIDATOR,
  });

  return (
    <Box>
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={onDelete}
      />
      <Container maxW="container.xll" padding={'20px'}>
        <Box>
          <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.ENQUIRY, true)} />
        </Box>
        <Box color={colors.white} display={'flex'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'20px'}>
          <Box>
            <Heading variant={'h1'}>Enquiry</Heading>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={'20px'}>
            <Button onClick={handleToggle} variant={'secondary'}>
              <GoFilter fontSize={'20px'} />
              <Text marginLeft={'5px'} className="btnText ">
                Filter
              </Text>
              <Box
                position={'absolute'}
                top={'-19px'}
                right={'-1'}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={'16px'}
                padding={'0px'}
                height={'30px'}
                width={'30px'}
                lineHeight={'30px'}
              >
                {activeFilter}
              </Box>
            </Button>
            {props.hasCreate && (
              <Button
                fontSize={{ base: '12px', sm: '13px', md: '14px', lg: '14px' }}
                variant={'primary'}
                onClick={() => {
                  navigate(ROUTE_LINKS[MENU_NAMES.ADD_ENQUIRY], {
                    state: {
                      isEdit: false,
                      enquiryId: enquiryId,
                      currentPage:currentPage,
                      currentPageSize:currentPageSize
                    },
                  });
                }}
              >
                <IoMdAddCircleOutline fontSize={'20px'} />
                <Text marginLeft={'5px'} className="btnText ">
                  Create New
                </Text>
              </Button>
            )}
          </Box>
        </Box>
        <Box backgroundColor={colors.white} boxShadow={'0px 0px 35px 0px #F5F5F5'} borderRadius={'10px'} marginBottom={'20px'} className="filter-wrapper">
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                let indexes = [];
                const payloadArr = createFilterPayload(filterState);
                console.log(payloadArr, 'payloadArr');
                const finalArr = payloadArr.map((obj, index) => {
                  if (obj.propertyName === 'pendingfollowup') {
                    if (!obj.checked) indexes.push(index);
                    obj.propertyName = 'FollowUpDate';
                    obj.operator = FILTER_ENUMS.LessThan;
                    obj.value = convertDateFormat(new Date(), 'mm-dd-yyyy');
                    obj.isGrouped = true;
                  }

                  if (obj.propertyName === 'todayfollowup') {
                    if (!obj.checked) indexes.push(index);
                    obj.propertyName = 'FollowUpDate';
                    obj.operator = FILTER_ENUMS.Equals;
                    obj.value = convertDateFormat(new Date(), 'mm-dd-yyyy');
                    obj['isGrouped'] = true;
                  }
                  if (obj.propertyName === 'futurefollowup') {
                    if (!obj.checked) indexes.push(index);
                    obj.propertyName = 'FollowUpDate';
                    obj.operator = FILTER_ENUMS.GreaterThan;
                    obj.value = convertDateFormat(new Date(), 'mm-dd-yyyy');
                    obj.isGrouped = true;
                  }

                  return obj;
                });
                if (indexes.length) {
                  for (var i = indexes.length - 1; i >= 0; i--) finalArr.splice(indexes[i], 1);
                }
                const filterSum = getFilterCount(finalArr);
                setActiveFilter(filterSum);
                setAPIPayload({
                  ...apiPayload,
                  filter: finalArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });
                const jsonString = JSON.stringify(finalArr);
                handleActiveFilter('post', '', { moduleId: moduleId, StateJson: jsonString });

              }}
            >
              <SimpleGrid columns={{ base: '1', sm: '1', md: '5', lg: '5' }} spacingX="40px" padding={'20px'} paddingBottom={'0px'}>
                <TextInput
                  type="date"
                  name="fromDate"
                  value={filterState.fromDate?.value}
                  onChange={(e) => {
                    let D = new Date(e.target.value).setDate(new Date(e.target.value).getDate() + 1);
                    setMinToDate(convertDateFormat(D, 'yyyy-mm-dd'));
                    handleFilterChange(e, filterState, setFilterState, 'From');
                    if (e.target.value != '') {
                      setIsToDate(false);
                    }
                  }}
                  placeholder={''}
                  variant="flushed"
                  label={{ text: 'From' }}
                />
                <TextInput
                  type="date"
                  isDisabled={isToDate}
                  name="toDate"
                  minVal={minToDate}
                  defaultValue={minToDate}
                  value={filterState.toDate?.value}
                  onChange={(e) => {
                    handleFilterChange(e, filterState, setFilterState, 'To Date');
                  }}
                  placeholder={''}
                  variant="flushed"
                  label={{ text: 'To' }}
                />
                <TextInput
                  type="text"
                  placeholder={''}
                  name="Name"
                  value={filterState.Name?.value}
                  onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Name')}
                  marginTop="20px"
                  formControl={{ id: 'name' }}
                  label={{ text: 'Name' }}
                />
                <SelectInput
                  formControl={{ id: 'enquiryStatusId', variant: 'floating' }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.EnquiryStatusId?.value}
                  name="EnquiryStatusId"
                  onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Enquiry Status')}
                  label={{
                    text: 'Enquiry Status',
                  }}
                  options={enquiryStatus}
                  showAllOption={true}
                />
                <TextInput
                  type="number"
                  placeholder={''}
                  name="EnquiryNumber"
                  value={filterState.EnquiryNumber?.value}
                  onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Enquiry Number')}
                  marginTop="20px"
                  formControl={{ id: 'enquiryNumber' }}
                  label={{ text: 'Enquiry Number' }}
                />
                <TextInput
                  type="text"
                  placeholder={''}
                  name="Address"
                  value={filterState.Address?.value}
                  onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Address')}
                  marginTop="20px"
                  formControl={{ id: 'address' }}
                  label={{ text: 'Address' }}
                />
                <TextInput
                  type="text"
                  placeholder={''}
                  name="PhoneNumber"
                  value={filterState.PhoneNumber?.value}
                  onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Phone Number')}
                  marginTop="20px"
                  formControl={{ id: 'phoneNumber' }}
                  label={{ text: 'Phone Number' }}
                />
                <SelectInput
                  formControl={{
                    id: 'counsellorId',
                    variant: 'floating',
                  }}
                  isRequired={false}
                  isReadOnly={false}
                  name="CounsellorId"
                  value={filterState.CounsellorId?.value}
                  label={{
                    text: 'Counsellor',
                  }}
                  options={counsellors}
                  onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Counsellor')}
                  showAllOption={true}
                />
                <Checkbox
                  __css={{
                    '& .chakra-checkbox__control': {
                      background: '#e2e8f0',
                    },
                  }}
                  type="checkbox"
                  iconSize="30px"
                  size={'md'}
                  name="pendingfollowup"
                  display={'flex'}
                  isChecked={filterState.pendingfollowup?.checked}
                  colorScheme="yellow"
                  alignItems={'center'}
                  onChange={(e) => handleCheckboxFilterChange(e, filterState, setFilterState, 'Pending followup')}
                >
                  <Text fontSize={13}>Pending followup</Text>
                </Checkbox>
                <Checkbox
                  __css={{
                    '& .chakra-checkbox__control': {
                      background: '#e2e8f0',
                    },
                  }}
                  iconSize="30px"
                  size={'md'}
                  type="checkbox"
                  name="todayfollowup"
                  isChecked={filterState.todayfollowup?.checked}
                  display={'flex'}
                  colorScheme="yellow"
                  alignItems={'center'}
                  onChange={(e) => handleCheckboxFilterChange(e, filterState, setFilterState, 'Today followup')}
                >
                  <Text fontSize={13}>Today followup</Text>
                </Checkbox>
                <Checkbox
                  __css={{
                    '& .chakra-checkbox__control': {
                      background: '#e2e8f0',
                    },
                  }}
                  iconSize="30px"
                  size={'md'}
                  display={'flex'}
                  type="checkbox"
                  name="futurefollowup"
                  isChecked={filterState.futurefollowup?.checked}
                  colorScheme="yellow"
                  alignItems={'center'}
                  onChange={(e) => handleCheckboxFilterChange(e, filterState, setFilterState, 'Future followup')}
                >
                  <Text fontSize={13}>Future followup</Text>
                </Checkbox>
              </SimpleGrid>
              <Box display={'flex'} justifyContent={'end'} paddingRight={'20px'} paddingBottom={'20px'}>
                <Button
                  fontSize={{
                    base: '12px',
                    sm: '13px',
                    md: '14px',
                    lg: '14px',
                  }}
                  variant={'primary'}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: '12px',
                    sm: '13px',
                    md: '14px',
                    lg: '14px',
                  }}
                  variant={'warning'}
                  marginLeft={'10px'}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>

        <Grid
          name={'enquiry'}
          columns={GRID_COLUMNS.ENQUIRY}
          aColumns={columns}
          data={data}
          navigateUrl={ROUTE_LINKS[MENU_NAMES.ENQUIRY]}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          permission={props}
          hasDelete={true}
          hasExport={hasExport}
          isPopupComponent={true}
          setColumns={(e) => setColumns(e)}
          viewRoute={ROUTE_LINKS[MENU_NAMES.EDIT_VIEW_ENQUIRY]}
          editRoute={ROUTE_LINKS[MENU_NAMES.EDIT_VIEW_ENQUIRY]}
          isPopupNavComponent={true}
          otherActions={GRID_OTHER_ACTIONS.ENQUIRY}
          modeTypes={followUpColors}
          setRecordId={(id, props) => {
            setEnquiryId(id);
            if (props.isDelete) setIsDeleteOpen(true);
            else if (props.updateStatus) {
              setEnquiryState({
                ...enquiryState,
                enquiryStatusId: props.enquiryStatusId,
              });
              setEnquiryOpen(true);
            } else if (props.specialRemarks) {
              setRemarkOpen(true);
            } else if (props.updateFollowup) {
              setMarkFollowupOpen(true);
            }
          }}
        />

        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) => {
            setCurrentPage(page);
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }}
          handleRowStateChange={(we) => {
            setCurrentPageSize(we.target.value);
            setAPIPayload({
              ...apiPayload,
              pageSize: we.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
      <Modal closeOnOverlayClick={false} isOpen={isRemarkOpen} onClose={onRmarkClose} minWidth={'20%'} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Formik
            validationSchema={validationSchema}
            initialValues={remarkState}
            enableReinitialize={true}
            onSubmit={(values) => {
              let payload = {
                enquiryId: enquiryId,
                remark: values.remark,
              };
              api
                .post('Enquiry/create-remark', payload)
                .then((res) => {
                  onRmarkClose();
                  SuccessAlert('Remark added sucessfully');
                  getAllRecords();
                  setRemarkState({
                    enquiryFileId: '',
                    remark: '',
                  });
                  setEnquiryFileId(null);
                })
                .catch((err) => {
                  console.log(err, 'err');
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalHeader borderBottom={'1px solid #EDEDED'}>Add Special Remark</ModalHeader>
                <ModalCloseButton bg={colors.primaryColor} color={colors.white} position={'absolute'} top={'15px'} onClick={onModelClose} />
                <ModalBody padding={'20px'}>
                  <SimpleGrid columns={{ base: '1', sm: '1', md: '1', lg: '1' }} spacingX="-3px" spacingY="-6px" gap={'10px'}>
                    <TextArea
                      name="remark"
                      value={values.remark}
                      placeholder={''}
                      resize="auto"
                      rows={20}
                      w={'100%'}
                      height="unset"
                      minH={'300px!important'}
                      label={{
                        text: 'Special Remark',
                        colors: errors.remark ? 'red !important' : '',
                        variant: errors.remark ? 'errored' : '',
                      }}
                      onChange={handleChange}
                      variant={errors.remark ? 'errored' : 'flushed'}
                    />
                  </SimpleGrid>
                </ModalBody>
                <ModalFooter justifyContent={'space-between'} borderTop={'1px solid #EDEDED '}>
                  <Button variant={'secondaryOutline'} onClick={onModelClose}>
                    Discard
                  </Button>
                  <Button variant={'primary'} color={colors.white} type="submit">
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      {/* Update Enquiry Status */}

      <Modal closeOnOverlayClick={false} isOpen={isEnquiryOpen} onClose={onEnquiryClose} minWidth={'20%'} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Formik
            validationSchema={enquiryStateValidationSchema}
            initialValues={enquiryState}
            enableReinitialize={true}
            onSubmit={(values) => {
              let payload = {
                enquiryId: enquiryId,
                enquiryStatusId: values.enquiryStatusId,
              };
              api
                .put('Enquiry/replace-enquiry-status', payload)
                .then((res) => {
                  SuccessAlert('Status updated sucessfully');
                  onEnquiryClose();
                  getAllRecords();
                  setEnquiryFileId(null);
                })
                .catch((err) => {
                  console.log(err, 'err');
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalHeader borderBottom={'1px solid #EDEDED'}>Update Status</ModalHeader>
                <ModalCloseButton bg={colors.primaryColor} color={colors.white} position={'absolute'} top={'15px'} onClick={onEnquiryClose} />
                <ModalBody padding={'20px'}>
                  <SimpleGrid columns={{ base: '1', sm: '1', md: '1', lg: '1' }} spacingX="-3px" spacingY="-6px" gap={'10px'}>
                    <SelectInput
                      formControl={{
                        id: 'enquiryStatusId',
                        variant: 'floating',
                      }}
                      isRequired={true}
                      isReadOnly={isLoading}
                      value={values.enquiryStatusId}
                      label={{
                        text: 'Enquiry Status',
                        colors: errors.enquiryStatusId ? 'red !important' : '',
                        variant: errors.enquiryStatusId ? 'errored' : '',
                      }}
                      name="enquiryStatusId"
                      onChange={handleChange}
                      options={enquiryStatus}
                      variant={errors.enquiryStatusId ? 'errored' : 'flushed'}
                    />
                  </SimpleGrid>
                </ModalBody>
                <ModalFooter justifyContent={'space-between'} borderTop={'1px solid #EDEDED '}>
                  <Button variant={'secondaryOutline'} onClick={onEnquiryClose}>
                    Discard
                  </Button>
                  <Button variant={'primary'} color={colors.white} type="submit">
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
      {/* MARK FOLLOWUP */}
      <Modal closeOnOverlayClick={false} isOpen={isMarkFollowupOpen} onClose={onMarkFollowupClose} minWidth={'20%'} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Formik
            validationSchema={validationSchema}
            initialValues={markFollowup}
            enableReinitialize={true}
            onSubmit={(values) => {
              let payload = {
                enquiryId: enquiryId,
                followUpDate: values.followUpDate,
                remark: values.remark,
              };
              api
                .post('/Enquiry/replace-followup', payload)
                .then((res) => {
                  onMarkFollowupClose();
                  SuccessAlert('Mark Followup added sucessfully');
                  getAllRecords();
                  setmarkFollowup({
                    enquiryId: '',
                    remark: '',
                    followUpDate: '',
                  });
                })
                .catch((err) => {
                  console.log(err, 'err');
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalHeader borderBottom={'1px solid #EDEDED'}>Mark Followup</ModalHeader>
                <ModalCloseButton bg={colors.primaryColor} color={colors.white} position={'absolute'} top={'15px'} onClick={onModelClose} />
                <ModalBody padding={'20px'}>
                  <SimpleGrid columns={{ base: '1', sm: '1', md: '1', lg: '1' }} spacingX="-3px" spacingY="-6px" gap={'10px'}>
                    <TextInput
                      type="date"
                      name="followUpDate"
                      isRequired={false}
                      onChange={handleChange}
                      placeholder={''}
                      variant={errors.followUpDate ? 'errored' : 'flushed'}
                      label={{
                        text: 'FollowUp Date',
                        colors: errors.followUpDate ? 'red !important' : '',
                        variant: errors.followUpDate ? 'errored' : '',
                      }}
                      isReadOnly={isLoading}
                    />
                    <TextArea
                      name="remark"
                      value={values.remark}
                      placeholder={''}
                      resize="auto"
                      rows={20}
                      w={'100%'}
                      height="unset"
                      minH={'300px!important'}
                      label={{
                        text: 'Special Remark',
                        colors: errors.remark ? 'red !important' : '',
                        variant: errors.remark ? 'errored' : '',
                      }}
                      onChange={handleChange}
                      variant={errors.remark ? 'errored' : 'flushed'}
                    />
                  </SimpleGrid>
                </ModalBody>
                <ModalFooter justifyContent={'space-between'} borderTop={'1px solid #EDEDED '}>
                  <Button variant={'secondaryOutline'} onClick={onModelClose}>
                    Discard
                  </Button>
                  <Button variant={'primary'} color={colors.white} type="submit">
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default Enquiry;
