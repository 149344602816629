import React, { useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  Tbody,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  Td,
  Th,
  Tr,
  ModalFooter,
  Table,
  Thead,
  useDisclosure,
  SimpleGrid,
  Select,
  Collapse,
  Switch,
  CircularProgress,
  Text,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
} from "@chakra-ui/react";

import { Checkbox } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Route, useNavigate } from "react-router-dom";
import { useState } from "react";
import { colors } from "../../../theme/styles/colors";
import BreadcrumbBox from "../../../theme/components/breadcrumb";
import TextInput from "../../../theme/components/input/text-input";
import { Form, Formik } from "formik";
import { ROUTE_LINKS } from "../../../core/utils/route-links";
import * as yup from "yup";
import Pagination from "../../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../../core/utils/navbar-items";
import {
  EXPORT_FILE_EXTENSION,
  EXPORT_FILE_TYPE,
  MENU_NAMES,
  PAGE_SIZE,
  ROLES,
} from "../../../core/utils/constants";
import { GRID_COLUMNS } from "../../../core/utils/grid-columns";
import SelectInput from "../../../theme/components/input/select-input";
import { GoFilter } from "react-icons/go";
import { BiExport } from "react-icons/bi";
import { IoMdAddCircleOutline } from "react-icons/io";
import MultiSelectInput from "../../../theme/components/input/multiselect-input";
import useTableHeightFix from "../../../core/hooks/useTableHeightFix";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GrFormView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import api from "../../../core/api";
import {
  EXPORT_TO_EXCEL,
  getFilterCount,
  convertDateFormat,
  createFilterPayload,
  deleteById,
  getAll,
  getById,
  handleFilterChange,
  handleResetState,
  CREATE_RESPONSE,
} from "../../../core/utils/common-function";
import DeleteModal from "../../../theme/components/modals/delete-modal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import SCHEMA_VALIDATOR from "../../../core/utils/schema-validator";
import SwitchInput from "../../../theme/components/input/switch-input";
import { useSelector } from "react-redux";
import Grid from "../../../theme/components/grid/grid";

const Setting = (props) => {
  const selector = useSelector((_) => _);
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [current, setCurrent] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [settingId, setSettingId] = useState(null);
  const [show, setShow] = useState(false);
  const [tableRowData, setTableRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.SETTING.filter((x) => x.Active && x.Visibility)
  );
  const [initialState, setInitialState] = useState({
    ipAddress: "",
    isActive: false,
    isAllowAccess: false,
  });
  const [filterState, setFilterState] = useState({});
  const [activeFilter, setActiveFilter] = useState(0);
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
  });
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const {
    POSITIVE_INTEGER,
    STRING_VALIDATOR,
    GUID_VALIDATOR,
    IP_ADDRESS_VALIDATOR,
    BOOLEAN_VALIDATOR,
  } = SCHEMA_VALIDATOR;

  const validationSchema = yup.object({
    ipAddress: IP_ADDRESS_VALIDATOR,
  });

  
  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };

  const handleToggle = () => {
    setShow(!show);
  };

  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
  };

  const getAllRecords = () => {
    api
      .post("/ConfigrationSetting/getAll", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.SETTING, res.data.data.data));
      })
      .catch((err) => { });
  };

  const getRecord = async () => {
    try {
      if (settingId) {
        let record = (await getById("ConfigrationSetting", settingId)).data;
        setInitialState({
          active: record.isActive,
          isAllowAccess: record.allowAccess,
          ipAddress: record.ipAddress,
          ...record,
        })
        onOpen();
      }
    } catch (error) { }
  };



  const onModelClose = () => {
    setSettingId(null);
    setIsEdit(false);
    setIsView(false);
    onClose();
    setInitialState({
      active: false,
      name: "",
    });
  };
  const onDelete = async () => {
    await deleteById("ConfigrationSetting", settingId);
    getAllRecords();
    setIsDeleteOpen(false);
    onModelClose();
  };

  useEffect(() => {
    getRecord();
  }, [isEdit, isView]);

  useEffect(() => {
    document.title = "Setting | Digital Filing";
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getAllRecords();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId]);

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [windowHeight]);
  return (
    <Box>
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={onDelete}
      />
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.SETTING)} />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>Setting</Heading>
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText ">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"0px"}
                height={"30px"}
                width={"30px"}
                lineHeight={"30px"}
              >
                {activeFilter}
              </Box>
            </Button>
            {props.hasCreate && (
              <Button variant={"primary"} onClick={onOpen}>
                <IoMdAddCircleOutline fontSize={"20px"} />
                <Text marginLeft={"5px"} className="btnText ">
                  Create New
                </Text>
              </Button>
            )}
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(filterState);
                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });
              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "4", lg: "4" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <TextInput
                  type="text"
                  placeholder={""}
                  name="IpAddress"
                  value={filterState.IpAddress?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  marginTop="20px"
                  formControl={{ id: "IpAddress" }}
                  label={{ text: "IpAddress" }}
                />
                <SelectInput
                  formControl={{ id: "isActive", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.IsActive?.value}
                  name="IsActive"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "Status",
                  }}
                  options={[
                    { text: "Active", value: true },
                    { text: "Inactive", value: false },
                  ]}
                />
            
                <SelectInput
                  formControl={{ id: "isAllowAccess", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.AllowAccess?.value}
                  name="AllowAccess"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "IP Access",
                  }}
                  options={[
                    { text: "Allowed", value: true },
                    { text: "Not Allowed", value: false },
                  ]}
                />
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>

        <Grid
          name={"setting"}
          columns={GRID_COLUMNS.SETTING}
          aColumns={columns}
          data={data}
          permission={props}
          hasDelete={true}
          hasExport={hasExport}
          setColumns={(e) =>
            setColumns(e)
          }
          isPopupComponent={true}
          setRecordId={(settingId, props) => {
            if (props.isView) {
              setIsView(true);
              setIsEdit(false);
            } else if (props.isEdit) {
              setIsView(false);
              setIsEdit(true);
            } else if (props.isDelete) setIsDeleteOpen(true);
            setSettingId(settingId);
          }}
        />
        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) =>
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          handleRowStateChange={(e) => {
            setAPIPayload({
              ...apiPayload,
              pageIndex: 1,
              pageSize: e.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onModelClose}
        minWidth={"20%"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialState}
            enableReinitialize={true}
            onSubmit={(values) => {
              let finalPayload = {
                ipAddress: values.ipAddress,
                branchId: selector.branch.userBranchId,
                isActive: values.isActive,
                allowAccess: values.isAllowAccess,
              };
              if (isEdit) {
                finalPayload.configrationSettingId = settingId;
                api
                  .put(`/ConfigrationSetting/replace`, { ...finalPayload })
                  .then((res) => {
                    onModelClose();
                    getAllRecords();
                  })
                  .catch((err) => {
                    console.log(err, "err");
                  });
                return;
              }
              api
                .post(`/ConfigrationSetting/create`, { ...finalPayload })
                .then((res) => {
                  onModelClose();
                  getAllRecords();
                })
                .catch((err) => {
                  console.log(err, "err");
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalHeader borderBottom={"1px solid #EDEDED"}>
                  {isEdit
                    ? "Update Setting"
                    : isView
                      ? "View Setting"
                      : "Create New Setting"}
                </ModalHeader>
                <ModalCloseButton
                  bg={colors.primaryColor}
                  color={colors.white}
                  position={"absolute"}
                  top={"15px"}
                  onClick={onModelClose}
                />
                <ModalBody padding={"20px"}>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                    spacingX="-3px"
                    spacingY="-6px"
                    gap={"10px"}
                  >
                    <TextInput
                      type="text"
                      name="ipAddress"
                      isRequired={true}
                      isReadOnly={isView}
                      placeholder={""}
                      value={values.ipAddress}
                      label={{
                        text: "IP Address",
                        colors: errors.ipAddress ? "red !important" : "",
                        variant: errors.ipAddress ? "errored" : "",
                      }}
                      onChange={(e) => handleChange(e)}
                      variant={errors.name ? "errored" : "flushed"}
                    />
                  </SimpleGrid>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                    spacingX="-3px"
                    spacingY="-6px"
                    gap={"10px"}
                  >
                  <SwitchInput
                      placeholder={""}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.isActive}
                      name="isActive"
                      variant={errors.isActive ? "errored" : "flushed"}
                      label={{
                        text: "Active",
                        colors: errors.isActive ? "red !important" : "",
                        variant: errors.isActive ? "errored" : "",
                        fontSize: "12px",
                      }}
                      formControl={{ id: "isActive" }}
                      onChange={handleChange}
                    />
                    <SwitchInput
                      placeholder={""}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.isAllowAccess}
                      name="isAllowAccess"
                      variant={errors.isAllowAccess ? "errored" : "flushed"}
                      label={{
                        text: "AllowAccess",
                        colors: errors.isAllowAccess ? "red !important" : "",
                        variant: errors.isAllowAccess ? "errored" : "",
                        fontSize: "12px",
                      }}
                      formControl={{ id: "isAllowAccess" }}
                      onChange={handleChange}
                    />
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter
                  justifyContent={"space-between"}
                  borderTop={"1px solid #EDEDED "}
                >
                  <Button variant={"secondaryOutline"} onClick={onModelClose}>
                    {isView ? "Back" : "Discard"}
                  </Button>
                  {!isView && (
                    <Button
                      variant={"primary"}
                      color={colors.white}
                      type="submit"
                      onClose={onModelClose}
                    >
                      {isLoading ? (
                        <CircularProgress
                          isIndeterminate
                          size="24px"
                          color={colors.white}
                        />
                      ) : isEdit ? (
                        "Save"
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  )}
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default Setting;
