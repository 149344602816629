import React, { useEffect } from "react";
import {
  Badge,
  Box,
  Container,
  Heading,
  useDisclosure,
  SimpleGrid,
  Collapse,
  Button,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoFilter } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { colors } from "../../theme/styles/colors";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import SelectInput from "../../theme/components/input/select-input";
import TextInput from "../../theme/components/input/text-input";
import {
  CREATE_RESPONSE,
  createFilterPayload,
  getAll,
  getFilterCount,
  handleFilterChange,
  handleResetState,
} from "../../core/utils/common-function";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import Pagination from "../../theme/components/pagination/pagination";
import useTableHeightFix from "../../core/hooks/useTableHeightFix";
import api from "../../core/api";
import { MENU_NAMES, PAGE_SIZE, ROLES } from "../../core/utils/constants";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import Grid from "../../theme/components/grid/grid";

const ViewPayment = (props) => {
  const selector = useSelector((_) => _);
  const [totalHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableRowData, setTableRowData] = useState([]);
  const navigate = useNavigate();
  const [bank, setBank] = useState([]);
  const params = useLocation();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [ViewPaymentId, setViewPaymentId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR || 
selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.VIEW_PAYMENT.filter((x) => x.Active && x.Visibility)
  );
  const [filterState, setFilterState] = useState({
    CreatedOn: "",
    guardianName: "",
    bankId: "",
  });
  const [apiPayload, setAPIPayload] = useState({
    filter: [
      {
        value: params.state.id,
        operator: "6",
        propertyName: "ImmigrationFileId",
        type: "text",
      },
    ],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
    branchId: selector.branch.userBranchId,
  });

  const [activeFilter, setActiveFilter] = useState(0);
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };
  const handleToggle = () => {
    setShow(!show);
  };

  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [
        {
          value: params.state.id,
          operator: "6",
          propertyName: "ImmigrationFileId",
          type: "text",
        },
      ],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
  };

  const getAllRecords = () => {
    api
      .post("/ImmigrationFile/getAllFund", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.VIEW_PAYMENT, res.data.data.data));
      })
      .catch((err) => {});
  };

  const getAllPromises = () => {
    const getBankRequest = getAll("Bank", payload);
    Promise.all([getBankRequest])
      .then((res) => {
        const [bankResponse, modeResponse] = res;
        const Bank = bankResponse.data.data.map((bank) => ({
          text: bank.name,
          value: bank.bankId,
        }));
        setBank(Bank);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    document.title = "View Payment | Digital Filing";
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getAllRecords();
      getAllPromises();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId, payload]);

  return (
    <Box>
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox
            route={GET_BREADCRUMB_ROUTES(MENU_NAMES.VIEW_PAYMENT)}
          />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>View Payment</Heading>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"0px"}
                height={"30px"}
                width={"30px"}
                lineHeight={"30px"}
              >
                {activeFilter}
              </Box>
            </Button>
            <Button
              variant={"primary"}
              onClick={() => {
                navigate(ROUTE_LINKS[MENU_NAMES.ADD_PAYMENT], {
                  state: {
                    immigrationFileId: params.state.id,
                  },
                });
              }}
            >
              <IoMdAddCircleOutline fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Create New
              </Text>
            </Button>
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(filterState);
                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                payloadArr.push({
                  value: params.state.id,
                  operator: "6",
                  propertyName: "ImmigrationFileId",
                  type: "text",
                });
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });
              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "3", lg: "3" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <TextInput
                  type="date"
                  name="CreatedOn"
                  value={filterState.CreatedOn?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  placeholder={""}
                  variant="flushed"
                  label={{ text: "Select Date" }}
                />
                <TextInput
                  type="text"
                  placeholder={""}
                  name="GuardianName"
                  value={filterState.GuardianName?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  marginTop="20px"
                  formControl={{ id: "guardianName" }}
                  label={{ text: "Guardian" }}
                />
                <SelectInput
                  formControl={{
                    id: "bankId",
                    variant: "floating",
                  }}
                  name="BankId"
                  value={filterState.BankId?.value}
                  label={{
                    text: "Bank",
                  }}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  options={bank}
                />
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  type="submit"
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                >
                  Search
                </Button>
                <Button
                  type="button"
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>

        <Grid
          name={"viewPayment"}
          columns={GRID_COLUMNS.VIEW_PAYMENT}
          aColumns={columns}
          data={data}
          permission={props}
          // hasDelete={true}
          hasAction={false}
          hasExport={hasExport}
          setColumns={(e) => setColumns(e)}
        />
        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) =>
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          handleRowStateChange={(e) => {
            setAPIPayload({
              ...apiPayload,
              pageIndex: 1,
              pageSize: e.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
    </Box>
  );
};
export default ViewPayment;
